import axios from '@/common/js/request'
//列表
export function itemPageList(params){
    const itemPageList = axios({
        url: '/arumSet/itemPageList',
        method: 'get',
        params
    })
    return itemPageList
}//列表
export function itemGeneralStatusToggle(params){
    const itemGeneralStatusToggle = axios({
        url: '/arumSet/itemGeneralStatusToggle',
        method: 'get',
        params
    })
    return itemGeneralStatusToggle
}//列表
export function addrsPageList(params){
    const addrsPageList = axios({
        url: '/arumAddr/addrsPageList',
        method: 'get',
        params
    })
    return addrsPageList
}//列表
export function addrPageList(params){
    const addrPageList = axios({
        url: '/arumAddr/addrPageList',
        method: 'get',
        params
    })
    return addrPageList
}//列表
 export function itemDetails2(params){
    const itemDetails2 = axios({
        url: '/arumSet/itemDetails2',
        method: 'get',
        params
    })
    return itemDetails2
}//列表
export function itemInsertOrUpdate(data){
    const itemInsertOrUpdate = axios({
        url: '/arumSet/itemInsertOrUpdate',
        method: 'post',
        data
    })
    return itemInsertOrUpdate
}//列表
export function addrsBatchDelete(data){
    const addrsBatchDelete = axios({
        url: '/arumAddr/addrsBatchDelete',
        method: 'post',
        data
    })
    return addrsBatchDelete
}//列
export function addrBatchDelete(data){
    const addrBatchDelete = axios({
        url: '/arumAddr/addrBatchDelete',
        method: 'post',
        data
    })
    return addrBatchDelete
}//列表
export function addrsBatchInsert(data){
    const addrsBatchInsert = axios({
        url: '/arumAddr/addrsBatchInsert',
        method: 'post',
        data
    })
    return addrsBatchInsert
}//列表
export function itemList(data){
    const itemList = axios({
        url: '/arumSet/itemList',
        method: 'post',
        data
    })
    return itemList
}//列表
export function templateInsertOrUpdate(data){
    const templateInsertOrUpdate = axios({
        url: '/arumSet/templateInsertOrUpdate',
        method: 'post',
        data
    })
    return templateInsertOrUpdate
}//列表
export function scuItemInsertOrUpdate(data){
    const scuItemInsertOrUpdate = axios({
        url: '/arumSet/scuItemInsertOrUpdate',
        method: 'post',
        data
    })
    return scuItemInsertOrUpdate
}
export function useraddrBatchInsert(data){
    const useraddrBatchInsert = axios({
        url: '/arumAddr/addrBatchInsert',
        method: 'post',
        data
    })
    return useraddrBatchInsert
}
export function templatePageList(params){
    const templatePageList = axios({
        url: '/arumSet/templatePageList',
        method: 'get',
        params
    })
    return templatePageList
}
export function deptDropDown(params){
    const deptDropDown = axios({
        url: '/arumAddr/deptDropDown',
        method: 'get',
        params
    })
    return deptDropDown
}
export function templateGeneralStatusToggle(params){
    const templateGeneralStatusToggle = axios({
        url: '/arumSet/templateGeneralStatusToggle',
        method: 'get',
        params
    })
    return templateGeneralStatusToggle
}
export function templateDetails(params){
    const templateDetails = axios({
        url: '/arumSet/templateDetails',
        method: 'get',
        params
    })
    return templateDetails
}
export function scuItemPageList(params){
    const scuItemPageList = axios({
        url: '/arumSet/scuItemPageList',
        method: 'get',
        params
    })
    return scuItemPageList
}
export function scuItemGeneralStatusToggle(params){
    const scuItemGeneralStatusToggle = axios({
        url: '/arumSet/scuItemGeneralStatusToggle',
        method: 'get',
        params
    })
    return scuItemGeneralStatusToggle
}
export function scuTemplatePageList(params){
    const scuTemplatePageList = axios({
        url: '/arumSet/scuTemplatePageList',
        method: 'get',
        params
    })
    return scuTemplatePageList
}
export function findScuItemTreeEcho(params){
    const findScuItemTreeEcho = axios({
        url: '/arumSet/findScuItemTreeEcho',
        method: 'get',
        params
    })
    return findScuItemTreeEcho
}
export function scuTemplateDetails(params){
    const scuTemplateDetails = axios({
        url: '/arumSet/scuTemplateDetails',
        method: 'get',
        params
    })
    return scuTemplateDetails
}
export function scuTemplateGeneralStatusToggle(params){
    const scuTemplateGeneralStatusToggle = axios({
        url: '/arumSet/scuTemplateGeneralStatusToggle',
        method: 'get',
        params
    })
    return scuTemplateGeneralStatusToggle
}
export function scuTemplateInsertOrUpdate(data){
    const scuTemplateInsertOrUpdate = axios({
        url: '/arumSet/scuTemplateInsertOrUpdate',
        method: 'post',
        data
    })
    return scuTemplateInsertOrUpdate
}
export function findTree(params){
    const findTree = axios({
        url: '/arumAddr/findTree',
        method: 'get',
        params
    })
    return findTree
}
export function userPageList(params){
    const userPageList = axios({
        url: '/arumArchive/userPageList',
        method: 'get',
        params
    })
    return userPageList
}
export function addrDropDown(params){
    const addrDropDown = axios({
        url: '/arumArchive/addrDropDown',
        method: 'get',
        params
    })
    return addrDropDown
}
export function userAllDelete(params){
    const userAllDelete = axios({
        url: '/arumArchive/userAllDelete',
        method: 'get',
        params
    })
    return userAllDelete
}
// 民用导入 23-12-28 17:29
export function importUserExcel(data) {
    const importUserExcel = axios({
        url: '/arumArchive/importUserExcel1',
        method: 'post',
        data,
    })
    return importUserExcel
}
export function userBatchDelete(data) {
    const userBatchDelete = axios({
        url: '/arumArchive/userBatchDelete',
        method: 'post',
        data,
    })
    return userBatchDelete
}
export function tableWithReplace(data) {
    const tableWithReplace = axios({
        url: '/arumArchive/tableWithReplace',
        method: 'post',
        data,
    })
    return tableWithReplace
}
export function userBatchSet(data) {
    const userBatchSet = axios({
        url: '/arumArchive/userBatchSet',
        method: 'post',
        data,
    })
    return userBatchSet
}
export function updatePageList(params) {
    const updatePageList = axios({
        url: '/arumArchive/updatePageList',
        method: 'get',
        params,
    })
    return updatePageList
}
export function userUpdateDetails(params) {
    const userUpdateDetails = axios({
        url: '/arumArchive/userUpdateDetails',
        method: 'get',
        params,
    })
    return userUpdateDetails
}
export function peopletableWithReplacePageList(params) {
    const peopletableWithReplacePageList = axios({
        url: '/arumArchive/tableWithReplacePageList',
        method: 'get',
        params,
    })
    return peopletableWithReplacePageList
}
export function securityCheckRecordPageList(params) {
    const securityCheckRecordPageList = axios({
        url: '/arumArchive/securityCheckRecordPageList',
        method: 'get',
        params,
    })
    return securityCheckRecordPageList
}
export function exportUserExcel(data) {
    const exportUserExcel = axios({
        url: '/arumArchive/exportUserExcel',
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        isloading: true,
        responseType: 'blob' //改变接收的值类型
    })
    return exportUserExcel
}

// 23.3.6
// 用户启用停用
export function updateUserGenerateStatus(params) {
    const updateUserGenerateStatus = axios({
        url: '/arumArchive/updateUserGenerateStatus',
        method: 'get',
        params,
    })
    return updateUserGenerateStatus
}
// 单元分页查询
export function addrssPageList(params) {
    const addrssPageLists = axios({
        url: '/arumAddr/addrssPageList',
        method: 'get',
        params,
    })
    return addrssPageLists
}
//单元批量新增
export function addrssBatchInsert(data){
    const addrssBatchInserts = axios({
        url: '/arumAddr/addrssBatchInsert',
        method: 'post',
        data
    })
    return addrssBatchInserts
}
//单元批量删除
export function addrssBatchDelete(data){
    const addrssBatchDeletes = axios({
        url: '/arumAddr/addrssBatchDelete',
        method: 'post',
        data
    })
    return addrssBatchDeletes
}