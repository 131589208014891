var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"content1"},[_c('div',{staticClass:"all"},[_c('div',{staticClass:"table"},[_c('el-table',{ref:"tableList",staticStyle:{"width":"100%","margin":"15px 0"},attrs:{"data":_vm.buildings,"border":"","height":"calc(100vh - 183px)","stripe":true,"header-cell-style":{
            color: '#333',
            fontFamily: 'MicrosoftYaHeiUI',
            fontSize: '14px',
            fontWeight: 900,
            background: '#f8f8f9',
            textAlign:'left',
          }}},[_c('el-table-column',{attrs:{"type":"index","label":"序号","width":"70"}}),(_vm.$route.query.type=='unUser')?[_c('el-table-column',{attrs:{"prop":"oldSheetSteel","label":"旧表表编号","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"oldTableTypeName","label":"旧表燃气表类型","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"oldTableDirectionName","label":"旧表表向","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"sheetSteel","label":"新表表编号","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"tableTypeName","label":"新表燃气表类型","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"tableDirectionName","label":"新表表向","show-overflow-tooltip":""}})]:_vm._e(),_c('el-table-column',{attrs:{"prop":"oldMechanicalData","label":"旧表机械数","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"newMechanicalData","label":"新表机械初值","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"residualVolume","label":"剩余气量","show-overflow-tooltip":""}}),(_vm.$route.query.type=='user')?_c('el-table-column',{attrs:{"prop":"tableDirectionName","label":"左右表","show-overflow-tooltip":""}}):_vm._e(),_c('el-table-column',{attrs:{"prop":"updateTableBy","label":"换表人","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"updateTableTime","label":"换表时间","show-overflow-tooltip":""}})],2),_c('el-pagination',{attrs:{"background":"","layout":"total, sizes, prev, pager, next, jumper","total":_vm.total,"page-sizes":[10, 30, 50, 100],"page-size":_vm.searchBox.size,"current-page":_vm.searchBox.current},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }