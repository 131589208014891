import axios from '@/common/js/request'
// 获取居民补户验收查询列表
export function getResident(params){ // 巡检计划分页列表
    const getResident = axios({
        url: '/indoor/getResident',
        method: 'get',
        params
    })
    return getResident
}
export function getIndustry(params){ // 巡检计划分页列表
    const getIndustry = axios({
        url: '/indoor/getIndustry',
        method: 'post',
        data:params
    })
    return getIndustry
}
export function tableWithUpdate(params){ // 巡检计划分页列表
    const tableWithUpdate = axios({
        url: '/AaumArchive/tableWithUpdate',
        method: 'post',
        data:params
    })
    return tableWithUpdate
}
export function tableWithReplace(params){ // 巡检计划分页列表
    const tableWithReplace = axios({
        url: '/AaumArchive/tableWithReplace',
        method: 'post',
        data:params
    })
    return tableWithReplace
}
export function tableWithBatchInsert(params){ // 巡检计划分页列表
    const tableWithBatchInsert = axios({
        url: '/AaumArchive/tableWithBatchInsert',
        method: 'post',
        data:params
    })
    return tableWithBatchInsert
}
export function getIndoorRectify(params){ // 巡检计划分页列表
    const getIndoorRectify = axios({
        url: '/outside/getIndoorRectify',
        method: 'get',
        params
    })
    return getIndoorRectify
}
export function tableWithDelete(params){ // 巡检计划分页列表
    const tableWithDelete = axios({
        url: '/AaumArchive/tableWithDelete',
        method: 'get',
        params
    })
    return tableWithDelete
}
export function tableWithUpdateGeneralStatus(params){ // 巡检计划分页列表
    const tableWithUpdateGeneralStatus = axios({
        url: '/AaumArchive/tableWithUpdateGeneralStatus',
        method: 'get',
        params
    })
    return tableWithUpdateGeneralStatus
}
export function tableWithPageList(params){ // 巡检计划分页列表
    const tableWithPageList = axios({
        url: '/AaumArchive/tableWithPageList',
        method: 'get',
        params
    })
    return tableWithPageList
}
export function getVillage(params){ // 巡检计划分页列表
    const getVillage = axios({
        url: '/indoor/getVillage',
        method: 'post',
        data:params
    })
    return getVillage
}
export function indoor_getCity(params){ 
    const indoor_getCity = axios({
        url: '/indoor/getCity',
        method: 'post',
        data:params
    })
    return indoor_getCity
}
// 	1验收申请 2pc城市集体户立管 3自检记录 4 室内单户验收 5工商福 6村村通 7看压申请 8pc城市集体户户内 9辐射环境 10：地面建筑物
export function prepare_getWorkProject(params){
    const prepare_getWorkProject = axios({
        url: '/prepare/getWorkProject',
        method: 'get',
        params
    })
    return prepare_getWorkProject
}
export function indoor_excelCity(params){ 
    const indoor_excelCity = axios({
        url: '/indoor/excelCity',
        method: 'post',
        data:params,
        responseType:'blob'

    })
    return indoor_excelCity
}
export function indoor_getAccept(params){ 
    const indoor_getAccept= axios({
        url: '/indoor/getAccept',
        method: 'get',
        params
    })
    return indoor_getAccept
}
export function indoor_finishAccept(params){ 
    const indoor_finishAccept= axios({
        url: '/indoor/finishAccept',
        method: 'get',
        params
    })
    return indoor_finishAccept
}
export function indoor_getFileRecord(params){
    const indoor_getFileRecord= axios({
        url: '/indoor/getFileRecord',
        method: 'get',
        params
    })
    return indoor_getFileRecord
}
export function indoor_getRecordDetails(params){
    const indoor_getRecordDetails= axios({
        url: '/indoor/getRecordDetails',
        method: 'get',
        params
    })
    return indoor_getRecordDetails
}
export function userUpdateDetails(params){
    const userUpdateDetails= axios({
        url: '/AaumArchive/userUpdateDetails',
        method: 'get',
        params
    })
    return userUpdateDetails
}
export function tabkeWithUpdateDetails(params){
    const tabkeWithUpdateDetails= axios({
        url: '/AaumArchive/tabkeWithUpdateDetails',
        method: 'get',
        params
    })
    return tabkeWithUpdateDetails
}
export function tableWithReplacePageList(params){
    const tableWithReplacePageList= axios({
        url: '/AaumArchive/tableWithReplacePageList',
        method: 'get',
        params
    })
    return tableWithReplacePageList
}
export function securityCheckRecordPageList(params){
    const securityCheckRecordPageList= axios({
        url: '/AaumArchive/securityCheckRecordPageList',
        method: 'get',
        params
    })
    return securityCheckRecordPageList
}
export function indoor_intoCity(params){
    const indoor_intoCity= axios({
        url: '/indoor/intoCity',
        method: 'post',
        data:params
    })
    return indoor_intoCity
}
export function indoor_getExcels(params){
    const indoor_getExcels= axios({
        url: '/indoor/getExcels',
        method: 'post',
        data:params
    })
    return indoor_getExcels
}
export function indoor_getStandPipe(params){
    const indoor_getStandPipe= axios({
        url: '/indoor/getStandPipe',
        method: 'post',
        data:params
    })
    return indoor_getStandPipe
}
export function updatePageList(params){
    const updatePageList= axios({
        url: '/AaumArchive/updatePageList',
        method: 'get',
        params
    })
    return updatePageList
}
export function indoor_excelStandPipe(params){ 
    const indoor_excelStandPipe = axios({
        url: '/indoor/excelStandPipe',
        method: 'post',
        data:params,
        responseType:'blob'

    })
    return indoor_excelStandPipe
}
export function indoor_intoStandPipe(params){ 
    const indoor_intoStandPipe = axios({
        url: '/indoor/intoStandPipe',
        method: 'post',
        data:params,

    })
    return indoor_intoStandPipe
}
export function indoor_intoVillage(params){ 
    const indoor_intoVillage = axios({
        url: '/indoor/intoVillage',
        method: 'post',
        data:params,
        'Content-Type': 'multipart/form-data'

    })
    return indoor_intoVillage
}
export function excelVillage(params){ 
    const indoor_excelVillage = axios({
        url: '/indoor/excelVillage',
        method: 'post',
        data:params,
        responseType:'blob'
    })
    return indoor_excelVillage
}


export function deleteData(params){  // 删除任务
    const deleteData = axios({
        url: '/indoor/delFileRecord',
        method: 'get',
        params
    })
    return deleteData
}
// 村村通验收导出(不带图片)
export function excelVillageNoImg(params){ 
    const excelVillageNoImg = axios({
        url: '/indoor/excelVillageNoImg',
        method: 'post',
        data:params,
        responseType:'blob'
    })
    return excelVillageNoImg
}

 